






























































































































import DestinationDialog from "./DestinationDialog.vue";
import Destination from "@web/views/launchpad/Destination.vue";
import Vue from "vue";
import Modal from "@web/components/modals/Modal.vue";
import LoadingWrapper from "@web/components/LoadingWrapper.vue";
import PageHeader from "@web/components/PageHeader.vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import { LAUNCHPAD_MODULE_NAME } from "@web/store/launchpad/launchpad";
import {
    DELETE_DESTINATION,
    FETCH_DESTINATIONS
} from "@web/store/launchpad/actions";
import { LaunchpadDestination } from "@backend/launchpad/types";
import {
    GET_ERROR,
    IS_EMPTY
} from "@web/store/launchpad/getters";

export default Vue.extend<any, any, any, any>({
    name: "Launchpad",
    components: {
        Modal,
        Destination,
        DestinationDialog,
        LoadingWrapper,
        PageHeader
    },
    data() {
        return {
            prefillData: undefined,
            busy: false,
            disabled: false,
        };
    },
    computed: {
        ...mapGetters({
            hasError: LAUNCHPAD_MODULE_NAME + GET_ERROR,
            isEmpty: LAUNCHPAD_MODULE_NAME + IS_EMPTY,
        }),
        destinations() {
            return this.$store.state.launchpad.destinations;
        },
        fetching() {
            return this.$store.state.launchpad.fetching;
        },
        hasMore() {
            return this.$store.state.launchpad.hasMore;
        },
    },
    methods: {
        ...mapActions({
            loadDestinations: LAUNCHPAD_MODULE_NAME + FETCH_DESTINATIONS,
            deleteDestination: LAUNCHPAD_MODULE_NAME + DELETE_DESTINATION,
        }),
        setDisabled(event: Event) {
            this.disabled = event;
        },
        setBusy(event: Event) {
            this.busy = event;
        },
        async createOrUpdate() {
            this.busy = true;
            await this.$refs.destinationDialog.createOrUpdateAndReset();
            this.busy = false;
            this.$modal.hide("destination-dialog");
        },
        resetDialog() {
            this.$refs.destinationDialog.reset();
        },
        showDeleteConfirm() {
            this.$modal.show("launchpad-delete-confirmation");
        },
        async deleteDestination() {
            this.busy = true;
            await this.$refs.destinationDialog.delete();
            this.busy = false;
            this.$modal.hide("launchpad-delete-confirmation");
            this.$modal.hide("destination-dialog");
        },
        cancelDelete() {
            this.$modal.hide("launchpad-delete-confirmation");
        },
        openDialog(destination: LaunchpadDestination) {
            if (destination) {
                this.prefillData = destination;
            } else {
                this.prefillData = undefined;
            }
            this.$modal.show("destination-dialog");
        },
    }
});
