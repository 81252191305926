


































import Vue from "vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import { LAUNCHPAD_MODULE_NAME } from "@web/store/launchpad/launchpad";
import {
    CREATE_DESTINATION,
    DELETE_DESTINATION,
    UPDATE_DESTINATION
} from "@web/store/launchpad/actions";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import { PageMetadataResolverService } from "@web/services/PageMetadataResolverService";
import Destination from "@web/views/launchpad/Destination.vue";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import Spinner from "@web/components/Spinner.vue";
import LinkInput from "@web/components/LinkInput.vue";

export default Vue.extend<any, any, any, any>({
    name: "DestinationDialog",
    components: {
        Destination,
        Spinner,
        LinkInput,
    },
    props: {
        prefillData: { type: Object, default: undefined },
    },
    data() {
        return {
            loading: false,
            invalidDestinationUrl: false,
            invalidIconUrl: false,
            url: "",
            title: "",
            icon: "",
            uid: this.prefillData ? this.prefillData.uid : "",
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET]),
        titlePlaceholder() {
            return this.loading ? "fetching data..." : "Placeholder";
        },
        iconPlaceholder() {
            return this.loading ? "fetching data..." : "https://...";
        },
        validUrl() {
            return "https://" + this.url;
        },
        invalidInputData() {
            return this.url.length === 0 || this.title.length === 0;
        },
    },
    watch: {
        invalidInputData(newInvalid) {
            this.$emit("disabled", newInvalid);
        }
    },
    methods: {
        ...mapActions({
            createDestination: LAUNCHPAD_MODULE_NAME + CREATE_DESTINATION,
            updateDestination: LAUNCHPAD_MODULE_NAME + UPDATE_DESTINATION,
            deleteDestination: LAUNCHPAD_MODULE_NAME + DELETE_DESTINATION,
        }),
        urlMatcher() {
            return new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(:\d{1,5}|\.[a-zA-Z]{2,})?\b([-a-zA-Z0-9@:%_+.~#?&//=()]*)/g);
        },
        validateIconUrl() {
            const iconText: string = this.$refs.iconInput.value;
            this.invalidIconUrl = !this.urlMatcher().test(iconText) && iconText.length > 0;
            this.$emit("disabled", this.invalidIconUrl);
        },
        async createOrUpdateAndReset() {
            if (this.prefillData?.uid) {
                await this.updateDestination({
                    title: this.title,
                    url: this.validUrl,
                    icon: this.icon,
                    uid: this.prefillData.uid,
                })
                    .then(() => {
                        analytics.log(getGlobalConfiguration().analytics_event_name_launchpad_destination_updated, {
                            intranetUid: this.intranet.uid,
                            destinationUid: this.prefillData.uid,
                        });
                    })
                    .catch((error: Error) => this.$notify({
                        group: "app",
                        type: "error",
                        text: error.name,
                        duration: 5000,
                    }));
            } else {
                await this.createDestination({
                    title: this.title,
                    url: this.validUrl,
                    icon: this.icon
                })
                    .then(() => {
                        analytics.log(getGlobalConfiguration().analytics_event_name_launchpad_destination_created, {
                            intranetUid: this.intranet.uid,
                        });
                    })
                    .catch((error: Error) => this.$notify({
                        group: "app",
                        type: "error",
                        text: error.message,
                        duration: 5000,
                    }));
            }
            this.reset();
        },
        async delete() {
            await this.deleteDestination({ destinationUid: this.prefillData?.uid })
                .then(() => {
                    analytics.log(getGlobalConfiguration().analytics_event_name_launchpad_destination_deleted, {
                        intranetUid: this.intranet.uid,
                        destinationUid: this.prefillData.uid,
                    });
                })
                .catch((error: Error) => this.$notify({
                    group: "app",
                    type: "error",
                    text: error.message,
                    duration: 5000,
                }));
        },
        reset() {
            this.title = "";
            this.url = "";
            this.icon = "";
        },
        async resolveMetaData() {
            const text: string = this.validUrl;
            const urlMatch = this.urlMatcher().test(text);
            if (urlMatch) {
                this.invalidDestinationUrl = false;
                this.loading = true;
                this.$emit("disabled", true);
                const pageMetadataResolverService = new PageMetadataResolverService();
                const { title, icon } = await pageMetadataResolverService.resolveMetadata(text);
                this.title = title || "";
                this.icon = icon || "";
                this.loading = false;
                this.$emit("disabled", false);
            } else {
                this.invalidDestinationUrl = text.length > 0;
            }
            this.$emit("disabled", this.invalidDestinationUrl);
        }
    },
    mounted() {
        this.title = this.prefillData?.title || "";
        this.url = this.prefillData?.url || "";
        if (this.url.startsWith("https://")) {
            this.url = this.url.substring(8);
        }
        this.icon = this.prefillData?.icon || "";
        this.$refs.urlInput.focus();
        this.$emit("disabled", true);
    }
});
