import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import axios,
{
    AxiosResponse,
    CancelTokenSource
} from "axios";
import { cloudRunUrl } from "@web/cloud-run-url";
import debounce from "lodash/debounce";

export class PageMetadataResolverService {
    public static readonly pageMetadataResolverEndpoint = `${cloudRunUrl.pageMetadataResolver}/api/page-metadata-resolver`;
    private readonly _DEBOUNCE_INTERVAL = 500;
    private readonly TIMEOUT_DURATION = 5000;

    private api: Promise<ApiClient>;
    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public resolveMetadata = debounce(async(url: string) => {
        const client = await this.api;
        // @ts-ignore
        const cancelTokenSource: CancelTokenSource = axios.CancelToken.source;
        const response: AxiosResponse = await client
            .get(PageMetadataResolverService.pageMetadataResolverEndpoint, {
                params: {
                    url
                },
                timeout: this.TIMEOUT_DURATION,
                cancelToken: cancelTokenSource.token,
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return new Promise(() => {});
                }
                return new Promise(() => error);
            });

        return response.data;
    }, this._DEBOUNCE_INTERVAL, { leading: true });
}
